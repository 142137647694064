import produce from "immer";
import { PolySynth, Sampler } from "tone";
import * as Tone from "tone";
import { shuffle, slice } from "lodash";

interface ActiveStep {
  isActive: true;
  content: {
    note: Tone.Unit.Frequency | Tone.Unit.Frequency[];
    duration: Tone.Unit.Time;
  };
}

interface InactiveStep {
  isActive: false;
}

type Step = ActiveStep | InactiveStep;

export type InstrumentType = "drum" | "synth" | "bass";

export type Chords = "C" | "Dm" | "Em" | "F" | "G" | "Am";

export interface Track {
  instrument: PolySynth | Sampler;
  type: InstrumentType;
  isActive: boolean;
  steps: Step[];
}

export interface ProjectState {
  steps: 16;
  chords: Chords[];
  tracks: Track[];
}

export const availableChords: Chords[] = ["C", "Dm", "Em", "F", "G", "Am"];

const randomizeChords = () => slice(shuffle(availableChords), 0, 4);

const TOTAL_STEPS = 16;

const createSynthTrackState = (chords: Chords[]) => {
  const mapping: Record<Chords, Tone.Unit.Frequency[]> = {
    C: ["C3", "E3", "G3", "C4", "E4", "G4"],
    Dm: ["D3", "F3", "A3", "D4", "F4", "A4"],
    Em: ["E3", "G3", "B3", "E4", "G4", "B4"],
    F: ["F3", "A3", "C4", "F4", "A4", "C5"],
    G: ["G3", "B3", "D4", "G4", "B4", "D5"],
    Am: ["A3", "C4", "E4", "A4", "C5", "E5"],
  };

  return chords
    .map((chord): Step[] => [
      { isActive: true, content: { note: mapping[chord], duration: "2n" } },
      { isActive: false },
      { isActive: false },
      { isActive: false },
    ])
    .flat();
};

const createBassTrackState = (chords: Chords[]) => {
  const mapping: Record<Chords, Tone.Unit.Frequency> = {
    C: "C2",
    Dm: "D2",
    Em: "E2",
    F: "F2",
    G: "G2",
    Am: "A2",
  };

  return chords
    .map((chord): Step[] => [
      { isActive: true, content: { note: mapping[chord], duration: "8n" } },
      { isActive: true, content: { note: mapping[chord], duration: "8n" } },
      { isActive: true, content: { note: mapping[chord], duration: "8n" } },
      { isActive: true, content: { note: mapping[chord], duration: "8n" } },
    ])
    .flat();
};

const createDrumTrackState = (chords: Chords[]): Step[] => {
  return [
    { isActive: true, content: { note: ["C4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4", "E4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4", "E4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4", "E4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
    { isActive: true, content: { note: ["C4", "E4"], duration: "2n" } },
    { isActive: true, content: { note: ["D4"], duration: "2n" } },
  ];
};

const createSteps = (chords: Chords[], instrumentType: InstrumentType) => {
  switch (instrumentType) {
    case "bass": {
      return createBassTrackState(chords);
    }

    case "synth": {
      return createSynthTrackState(chords);
    }

    case "drum": {
      return createDrumTrackState(chords);
    }
  }
};

export const createInitialProjectState = (): ProjectState => {
  const chords = randomizeChords();

  return {
    steps: TOTAL_STEPS,
    chords,
    tracks: [
      {
        type: "drum",
        instrument: new Tone.Sampler({
          urls: {
            C4: "kick.mp3",
            D4: "hihat.mp3",
            E4: "clap.mp3",
          },
          baseUrl: `${process.env.PUBLIC_URL}/`,
        }).toDestination(),
        isActive: true,
        steps: createSteps(chords, "drum"),
      },
      {
        type: "bass",
        instrument: new Tone.PolySynth().toDestination(),
        isActive: true,
        steps: createSteps(chords, "bass"),
      },
      {
        type: "synth",
        instrument: new Tone.PolySynth().toDestination(),
        isActive: true,
        steps: createSteps(chords, "synth"),
      },
    ],
  };
};

interface ActionBase {
  type: string;
  payload: Record<any, any>;
}

interface ToggleStepAction extends ActionBase {
  type: "TOGGLE_STEP";
  payload: {
    trackIndex: number;
    stepIndex: number;
  };
}

interface RefreshAction extends ActionBase {
  type: "REFRESH";
  payload: {};
}

interface ToggleTrackAction extends ActionBase {
  type: "TOGGLE_TRACK";
  payload: {
    trackIndex: number;
  };
}

export type ProjectAction =
  | ToggleStepAction
  | RefreshAction
  | ToggleTrackAction;

const projectReducer = (state: ProjectState, action: ProjectAction) => {
  switch (action.type) {
    case "TOGGLE_STEP": {
      return produce(state, (draftState) => {
        const { trackIndex, stepIndex } = action.payload;
        const stepWasActive =
          state.tracks[trackIndex].steps[stepIndex].isActive;

        if (stepWasActive) {
          draftState.tracks[trackIndex].steps[stepIndex] = {
            isActive: false,
          };

          return;
        }

        draftState.tracks[trackIndex].steps[stepIndex] = {
          isActive: true,
          content: {
            note: "C4",
            duration: "4n",
          },
        };
      });
    }

    case "REFRESH": {
      const chords = randomizeChords();

      return produce(state, (draftState) => {
        draftState.chords = chords;

        draftState.tracks = state.tracks.map((track) => {
          return {
            ...track,
            steps: createSteps(chords, track.type),
          };
        });
      });
    }

    case "TOGGLE_TRACK": {
      return produce(state, (draftState) => {
        draftState.tracks[action.payload.trackIndex].isActive =
          !state.tracks[action.payload.trackIndex].isActive;
      });
    }
  }
};

export default projectReducer;
