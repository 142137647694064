import React from "react";
import { InstrumentType, Track } from "../../projectReducer";

import * as Tone from "tone";

import "./Instrument.css";

interface InstrumentProps {
  track: Track;
  currentStep: number;
  onToggleClick: () => void;
}

const notes: Tone.Unit.Frequency[] = [
  "C2",
  "D2",
  "E2",
  "F2",
  "G2",
  "A2",
  "B2",
  "C3",
  "D3",
  "E3",
  "F3",
  "G3",
  "A3",
  "B3",
  "C4",
  "D4",
  "E4",
  "F4",
  "G4",
  "A4",
  "B4",
  "C5",
  "D5",
  "E5",
].reverse();

const instrumentTitle: Record<InstrumentType, string> = {
  drum: "Drums",
  bass: "Bass",
  synth: "Synth",
};

const Instrument = ({ track, currentStep, onToggleClick }: InstrumentProps) => {
  return (
    <div className="instrument__column">
      <div className={`instrument instrument--${track.type}`}>
        <header className="instrument__header">
          <h1 className="instrument__title">{instrumentTitle[track.type]}</h1>
          <div className="instrument__toggle-wrapper">
            <button
              className={
                track.isActive
                  ? "instrument__toggle"
                  : "instrument__toggle instrument__toggle--inactive"
              }
              onClick={() => onToggleClick()}
            >
              On
            </button>
          </div>
        </header>
        <div className="instrument__grid">
          {notes.map((note, noteIndex) => (
            <div className="instrument__row" key={noteIndex}>
              {track.steps.map((step, stepIndex) => {
                /**
                 * Todo: Jobbig logik:
                 */
                if (
                  (step.isActive &&
                    Array.isArray(step.content.note) &&
                    step.content.note.includes(note)) ||
                  (step.isActive && step.content.note === note)
                ) {
                  return (
                    <div
                      key={stepIndex}
                      className={`instrument__cell instrument__cell--active${
                        currentStep === stepIndex
                          ? " instrument__cell--playing"
                          : ""
                      }`}
                    />
                  );
                } else {
                  return <div key={stepIndex} className="instrument__cell" />;
                }
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Instrument;
