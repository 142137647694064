import { useEffect, useRef, useState } from "react";

import * as Tone from "tone";
import { ProjectState } from "../projectReducer";

const useTransport = (projectState: ProjectState) => {
  const [currentStep, setCurrentStep] = useState(0);
  const stepRef = useRef(currentStep);
  const projectStateRef = useRef(projectState);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    projectStateRef.current = projectState;
  }, [projectState]);

  useEffect(() => {
    const loop = (time: number) => {
      const { tracks } = projectStateRef.current;

      tracks.forEach((track) => {
        const step = track.steps[stepRef.current];

        if (step.isActive && track.isActive) {
          track.instrument.triggerAttackRelease(
            step.content.note,
            step.content.duration,
            time
          );
        }
      });

      setCurrentStep(stepRef.current);

      if (stepRef.current === projectStateRef.current.steps - 1) {
        stepRef.current = 0;
      } else {
        stepRef.current = stepRef.current + 1;
      }
    };

    let loopRef = 0;

    if (isPlaying) {
      loopRef = Tone.Transport.scheduleRepeat(loop, "8n");
      Tone.Transport.start();
    } else {
      Tone.Transport.stop();
      Tone.Transport.clear(loopRef);
      stepRef.current = 0;
      setCurrentStep(0);
    }

    return () => {
      Tone.Transport.clear(loopRef);
    };
  }, [isPlaying]);

  const start = () => {
    setIsPlaying(true);
  };

  const stop = () => {
    setIsPlaying(false);
  };

  return {
    start,
    stop,
    isPlaying,
    currentStep,
  };
};

export default useTransport;
