import React, { useMemo, useReducer, useState } from "react";
import "./App.css";

import * as Tone from "tone";
import useTransport from "./hooks/useTransport";
import projectReducer, { createInitialProjectState } from "./projectReducer";
import Instrument from "./components/Instrument/Instrument";

const synth = new Tone.Synth().toDestination();

function App() {
  const initialState = useMemo(() => createInitialProjectState(), []);
  const [projectState, dispatch] = useReducer(projectReducer, initialState);
  const [started, setStarted] = useState(false);

  const { start, stop, isPlaying, currentStep } = useTransport(projectState);

  const togglePlayback = () => {
    if (isPlaying) {
      stop();
    } else {
      start();
    }
  };

  return (
    <div className="App">
      <div className="header">
        {!started && (
          <div className="header__overlay">
            <div className="header__text">
              <button
                onClick={() => {
                  const now = Tone.now();
                  synth.triggerAttackRelease("C6", "8n", now);
                  synth.triggerAttackRelease("E6", "8n", now + 0.05);
                  synth.triggerAttackRelease("G6", "8n", now + 0.1);
                  synth.triggerAttackRelease("A6", "8n", now + 0.1);
                  setStarted(true);
                }}
                className="header__button"
              >
                Create chord progression
              </button>
            </div>
          </div>
        )}
        <div className="header__section">
          <h1 className="header__logo">
            Maud <span className="header__version">0.7</span>
          </h1>
        </div>
        <div className="header__section">
          {projectState.chords.map((chord) => (
            <span key={chord} className="header__text">
              {chord}&nbsp;
            </span>
          ))}
        </div>
        <div className="header__section">
          <span className="header__text">
            <button className="header__button" onClick={() => togglePlayback()}>
              {isPlaying ? "Pause" : "Play"}
            </button>
          </span>
        </div>
        <div className="header__section">
          <span className="header__text">
            <button
              className="header__button"
              onClick={() => dispatch({ type: "REFRESH", payload: {} })}
            >
              Randomize
            </button>
          </span>
        </div>
      </div>
      <div className="instrument-wrapper">
        {projectState.tracks.map((track, trackIndex) => (
          <Instrument
            key={trackIndex}
            track={track}
            currentStep={currentStep}
            onToggleClick={() => {
              dispatch({ type: "TOGGLE_TRACK", payload: { trackIndex } });
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
